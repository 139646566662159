/**
 * DBS Slate Stylesheet
 * DBS>Interactive
 */

.page- {

	.home-hero {
		margin-bottom: 0;
	}

	.kit-slider,
	.kit-slider + .two-columns,
	.how-it-works {
		.contain {
			position: relative;
			z-index: 1;
		}
	}

	.kit-slider {
		@include vertical-padding();
		background: #101010;
		margin-bottom: 0;
		margin-top: 0;
		position: relative;

		&::after {
			background: radial-gradient(39.75% 58.57% at 82.5% 16.06%, rgba(105, 105, 105, .4) 0%, #101010 100%);
			content: '';
			height: 300%;
			position: absolute;
			top: 0;
			right: 0;
			width: 100%;
			z-index: 0;
		}

		+ .two-columns {
			@include vertical-padding();
			background: #101010;
			margin-top: 0;
			margin-bottom: 0;
		}
	}

	.kit-slider__right::before {background: #101010;}

	.how-it-works {
		@include vertical-padding;
		padding-top: 0 !important;
		background: linear-gradient(to bottom, #101010, $grayDarker);
		margin-bottom: 0;
		margin-top: 0;
		position: relative;

		&::before {
			background: radial-gradient(50% 50% at 50% 50%, #20343A 0%, rgba(30, 45, 50, 0.00) 100%);
			content: '';
			height: 200%;
			position: absolute;
				top: -50%;
				left: -50%;
			width: 100%;
			z-index: 0;
		}
	}

	@include media($screen-sm) {

	}
}