/**
 * DBS Slate Front Page Stylesheet
 * DBS>Interactive
 *
 * This stylesheet will only be loaded on the front page.
 */
/*! Flickity v2.2.1
https://flickity.metafizzy.co
---------------------------------------------- */
.flickity-enabled {
  position: relative; }

.flickity-enabled:focus {
  outline: none; }

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%; }

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%; }

/* draggable */
.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: grab; }

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: grabbing; }

/* ---- flickity-button ---- */
.flickity-button {
  position: absolute;
  background: rgba(255, 255, 255, 0.75);
  border: none;
  color: #333; }

.flickity-button:hover {
  background: white;
  cursor: pointer; }

.flickity-button:focus {
  outline: none;
  box-shadow: 0 0 0 5px #19F; }

.flickity-button:active {
  opacity: 0.6; }

.flickity-button:disabled {
  opacity: 0.3;
  cursor: auto;
  /* prevent disabled button from capturing pointer up event. #716 */
  pointer-events: none; }

.flickity-button-icon {
  fill: currentColor; }

/* ---- previous/next buttons ---- */
.flickity-prev-next-button {
  top: 50%;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  /* vertically center */
  transform: translateY(-50%); }

.flickity-prev-next-button.previous {
  left: 10px; }

.flickity-prev-next-button.next {
  right: 10px; }

/* right to left */
.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: 10px; }

.flickity-rtl .flickity-prev-next-button.next {
  right: auto;
  left: 10px; }

.flickity-prev-next-button .flickity-button-icon {
  position: absolute;
  left: 20%;
  top: 20%;
  width: 60%;
  height: 60%; }

/* ---- page dots ---- */
.flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: -25px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1; }

.flickity-rtl .flickity-page-dots {
  direction: rtl; }

.flickity-page-dots .dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 8px;
  background: #333;
  border-radius: 50%;
  opacity: 0.25;
  cursor: pointer; }

.flickity-page-dots .dot.is-selected {
  opacity: 1; }

/**
 * Global Mixins
 * DBS>Interactive
 */
/**
 * Helpful mixin for font size scaling.
 *
 * Decimal values should not be used here!
 * The results will not be what you'd expect.
 *
 * @usage
 * 		@include font-scale(-1) - smaller
 * 		@include font-scale(0) = 1em (.8em in mobile)
 * 		@include font-scale(1) - larger
 * 			etc...
 */
/**
 * Fixes clear bug in certain situations
 *
 * @group layout
 * @link http://fuseinteractive.ca/blog/understanding-humble-clearfix#.VJ853sAEo Understanding the humble clearfix
 * @example scss - Usage
 *   @extend clearFix; // That's It!
 */
.clearfix:before, .clearfix:after {
  content: "";
  display: table; }

.clearfix:after {
  clear: both; }

/**
 * An easy way to truncate text with an ellipsis. Requires the element to be block or inline-block.
 *
 * @group Typography
 * @link http://web-design-weekly.com/2013/05/12/handy-sass-mixins/ Handy Sass Mixins
 * @example scss - Usage
 *   .text-truncate {
 *	  @extend text-truncate;
 *   }
 */
/**
 * Hide On Breakpoint
 *
 * @param {Variable} $bp - Breakpoint variable, ie $largeScreen
 * @param {String} $determinate ('min') - Whether it is max-width or min-width based
 * @group General
 * @link https://github.com/GumbyFramework/Gumby/blob/master/sass/functions/_visibility.scss Gumby Framework
 * @example scss - Basic Usage
 *   .class{
 *	  @include hideOn($largeScreen);
 *   }
 *
 * @example scss - Reversed Determinate
 *   @include hideOn($baby, 'max');
 */
/**
 * Show On Breakpoint
 *
 * @param {Variable} $bp - Breakpoint variable, ie $largeScreen
 * @param {String} $determinate ('min') - Whether it is max-width or min-width based
 * @group General
 * @link https://github.com/GumbyFramework/Gumby/blob/master/sass/functions/_visibility.scss Gumby Framework
 * @example scss - Basic Usage
 *   .class{
 *	  @include showOn($largeScreen);
 *   }
 *
 * @example scss - Reversed Determinate
 *   @include showOn($baby, 'max');
 */
/**
 * Size mixin - Sets width and height.
 *
 * When only one argument is specified, both the height and width are set to the same value.
 *
 * @param width - required
 * @param height
 */
/**
 * REFLEX ISH GRID
 */
/**
 * Exponent function
 *
 * NOTE: This does NOT work for decimal values
 *
 *   @see: https://css-tricks.com/snippets/sass/power-function/
 */
/**
 * Object Fit
 *
 * If a browser supports object-fit it will use it with the params
 * given. Otherwise it will fall back on a more basic image centering method.
 *
 * @param size - required (cover, contain, auto, etc.)
 * @param position - required (center, left, right, top, bottom right, etc.)
 */
/**
 * Aspect Ratio
 *
 * Used to create a padding box that an image/video can be placed in.
 *
 * Example @include aspect-ratio(16, 9);
 */
/**
 * Slate Layout Mixins
 * DBS>Interactive
 */
/**
 * Default responsive padding mixin for layout content
 */
/**
 * Default responsive margin mixin for layout content
 */
/**
 * Used for mimicking the vertical space provided by the default-margin mixin, but
 * with padding for colored background layouts.
 */
/**
 * Mixin to keep space (either margin or padding) between cells consistent
 *
 * It takes 2 arguments:
 * 		@param $space_property = { 'margin'|'padding' }
 * 		@param $reverse = { true|false }
 */
/**
 * Responsive layout mixin to constrain a width to containSize.
 */
/**
 * Theme Variables - colors, font sizes, breakpoint etc.
 * All the variables will goes here based on project tech specification
 * DBS>Interactive
 */
/**
 * Fonts
 */
/**
 * Type style definitions
 */
.blueprint {
  padding: 6rem 0 !important;
  position: relative; }
  .blueprint__text {
    opacity: 0;
    transition: opacity 0.75s ease-in-out; }
  .blueprint .animation-trigger.in-view ~ .contain .blueprint__text {
    opacity: 1; }
  .blueprint__graphic {
    height: 80%;
    position: absolute;
    top: 50%;
    left: 48%;
    transform: translateY(-50%);
    width: 50%; }
    .blueprint__graphic svg {
      height: 100%;
      width: 100%; }
  .blueprint #house-drawing .line {
    fill: transparent;
    stroke: rgba(255, 255, 255, 0.25);
    stroke-width: 3;
    stroke-dashoffset: 10000;
    stroke-dasharray: 10000;
    transition: 8s; }
  .blueprint #house-drawing.in-view .line {
    stroke-dashoffset: 0; }
  @media screen and (min-width: 48em) {
    .blueprint {
      padding: 7.5rem 0 !important; }
      .blueprint__text {
        max-width: 35rem; }
      .blueprint.text-center .blueprint__text {
        margin-left: auto;
        margin-right: auto; }
      .blueprint.text-right .blueprint__text {
        margin-left: auto; }
      .blueprint.text-right .blueprint__graphic {
        left: auto;
        right: 48%; } }

.how-it-works .animation-trigger.in-view ~ .how-it-works__left {
  opacity: 1; }

.how-it-works__left {
  margin-bottom: 1.5rem;
  opacity: 0;
  transition: 0.75s opacity ease-in-out; }
  .how-it-works__left-top {
    margin-bottom: 1.5rem; }

.how-it-works__cta-link-wrapper {
  padding-right: 1.5rem;
  width: 60%; }
  .how-it-works__cta-link-wrapper a {
    width: 100%; }

.how-it-works__cta-image {
  margin-left: auto;
  width: 40%; }

.how-it-works__cta:first-child {
  border-bottom: 1px solid rgba(255, 255, 255, 0.45);
  margin-bottom: 1rem;
  padding-bottom: 1rem; }

.how-it-works:first-child .how-it-works__left {
  animation: opacity 0.75s ease-in-out both;
  opacity: 1; }

@media screen and (min-width: 48em) {
  .how-it-works__left {
    margin-bottom: 0;
    padding-right: 3rem; }
  .how-it-works__right {
    padding-left: 3rem; } }

/**
 * Two Columns Flex Layout
 * DBS Interactive
 */
.two-columns__left, .two-columns__right {
  opacity: 0;
  position: relative;
  transition: opacity 0.75s ease-in-out, transform 0.75s ease-in-out; }
  .two-columns__left img, .two-columns__right img {
    display: block; }
  .two-columns__left.has-img, .two-columns__right.has-img {
    opacity: 0;
    transition: opacity 0.75s ease-in-out; }

.two-columns__left {
  transform: translate3d(-2rem, 0, 0); }

.two-columns__right {
  transform: translate3d(2rem, 0, 0); }

.two-columns .animation-trigger.in-view ~ .has-img {
  opacity: 1; }

.two-columns .animation-trigger.in-view ~ .two-columns__left,
.two-columns .animation-trigger.in-view ~ .two-columns__right {
  opacity: 1;
  transform: translate3d(0, 0, 0); }

@media screen and (max-width: 47.999em) {
  .two-columns__left {
    margin-bottom: 1.5rem; } }

@media screen and (min-width: 48em) {
  .two-columns__left, .two-columns__right {
    width: calc(50% - 3rem); }
    .two-columns__left.contain-img img, .two-columns__right.contain-img img {
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%; }
      @supports (object-fit: contain) {
        .two-columns__left.contain-img img, .two-columns__right.contain-img img {
          height: 100%;
          object-fit: contain;
          object-position: center; } }
    .two-columns__left.cover img, .two-columns__right.cover img {
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%; }
      @supports (object-fit: contain) {
        .two-columns__left.cover img, .two-columns__right.cover img {
          height: 100%;
          object-fit: cover;
          object-position: center; } }
    .two-columns__left.contain-img, .two-columns__left.cover, .two-columns__right.contain-img, .two-columns__right.cover {
      -ms-flex-item-align: stretch;
      -ms-grid-row-align: stretch;
      align-self: stretch; } }

@media screen and (min-width: 48em) {
  .two-columns.two-thirds-right .two-columns__left {
    width: calc(38% - 3rem); } }

.two-columns.two-thirds-right img {
  height: auto;
  width: 100%; }

.two-columns.two-thirds-right .two-columns__right.bg-img {
  padding-left: 0; }

@media screen and (min-width: 48em) {
  .two-columns.two-thirds-right .two-columns__right {
    width: calc(62% - 3rem); } }

.two-columns.two-thirds-left img {
  height: auto;
  width: 100%; }

@media screen and (min-width: 48em) {
  .two-columns.two-thirds-left .two-columns__left {
    width: calc(62% - 3rem); }
    .two-columns.two-thirds-left .two-columns__left.bg-img {
      padding-right: 0; }
  .two-columns.two-thirds-left .two-columns__right {
    width: calc(38% - 3rem); } }

.kits-layout__heading,
.kits-layout .button {
  opacity: 0;
  transition: 0.75s opacity ease-in-out, background 0.25s, color 0.25s; }

.kits-layout .button {
  transition-delay: 1.4s; }

.kits-layout__wrapper {
  margin-bottom: 1.5rem; }

.kits-layout__kit {
  margin-right: 2rem;
  width: calc(50% - 2rem); }
  .kits-layout__kit-image {
    height: 0;
    margin-bottom: .75rem;
    overflow: hidden;
    padding-bottom: 60%;
    position: relative; }
    .kits-layout__kit-image img {
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%; }
      @supports (object-fit: contain) {
        .kits-layout__kit-image img {
          height: 100%;
          object-fit: contain;
          object-position: center; } }

.kits-layout .animation-trigger.in-view ~ .kits-layout__heading,
.kits-layout .animation-trigger.in-view ~ p .button {
  opacity: 1; }

.kits-layout .flickity-button {
  background: url("/icons/slider-arrow.svg") no-repeat center !important;
  background-size: 100%;
  opacity: .25;
  transition: .25s opacity;
  transform: none; }
  .kits-layout .flickity-button.previous {
    background-image: url("/icons/slider-arrow-back.svg") !important;
    left: -1rem; }
  .kits-layout .flickity-button.next {
    right: -1rem; }
  .kits-layout .flickity-button:hover, .kits-layout .flickity-button:focus {
    opacity: 1; }
  .kits-layout .flickity-button .flickity-button-icon {
    display: none; }

@media screen and (min-width: 48em) {
  .kits-layout__kit {
    width: calc(33.33% - 2rem); } }

@media screen and (min-width: 62em) {
  .kits-layout__kit {
    width: calc(17% - 2rem); }
  .kits-layout .flickity-button.previous {
    left: -2rem; }
  .kits-layout .flickity-button.next {
    right: -2rem; } }

.admin {
  opacity: .75;
  position: fixed;
  right: 1.5rem;
  bottom: 1.5rem;
  transition: opacity .25s; }
  .admin.active, .admin:hover, .admin:focus {
    opacity: 1; }
  .admin.active .admin__links {
    opacity: 1;
    transition: opacity .25s 0s, visibility 0s .0s;
    visibility: visible; }
  .admin__toggle {
    appearance: none;
    background: white;
    border: none;
    border-radius: 50%;
    box-shadow: 0 0 1rem -0.5rem rgba(0, 0, 0, 0.75);
    height: 4rem;
    position: relative;
    width: 4rem; }
    .admin__toggle img {
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 75%;
      left: 55%;
      width: 75%; }
      @supports (object-fit: contain) {
        .admin__toggle img {
          height: 100%;
          object-fit: contain;
          object-position: center; } }
  .admin__links {
    background: white;
    border-radius: 1rem;
    box-shadow: 0 0 1rem -0.5rem rgba(0, 0, 0, 0.75);
    opacity: 0;
    padding: 1rem 1.5rem;
    position: absolute;
    bottom: 4.5rem;
    right: 0;
    transition: opacity .25s 0s, visibility 0s .25s;
    visibility: hidden; }
    .admin__links ul {
      list-style: none;
      margin: 0;
      text-align: right;
      padding: 0; }
      .admin__links ul li {
        margin-bottom: .5rem; }
        .admin__links ul li:last-child {
          margin-bottom: 0; }
        .admin__links ul li a {
          color: #232323;
          display: block;
          text-decoration: none; }
          .admin__links ul li a:after {
            background: #232323;
            content: '';
            display: block;
            height: 1px;
            position: relative;
            top: 0;
            transform: scaleX(0);
            transform-origin: 0 100%;
            transition: transform .25s ease-in-out; }
          .admin__links ul li a:hover, .admin__links ul li a:focus {
            text-decoration: none; }
            .admin__links ul li a:hover:after, .admin__links ul li a:focus:after {
              transform: scaleX(1);
              transform-origin: 100% 0; }

/**
 * DBS Chevron Styles
 * DBS>Interactive
 */
#dbs-chev {
  position: relative; }
  #dbs-chev svg {
    height: 2rem;
    max-width: 1rem; }
  #dbs-chev strong {
    font-size: 0.64em;
    float: right;
    font-weight: normal;
    text-align: center;
    width: 15em; }
    @media (min-width: 48em) {
      #dbs-chev strong {
        font-size: 0.8em; } }
    @media screen and (min-width: 48em) {
      #dbs-chev strong {
        width: 21em; } }
  #dbs-chev .text {
    background: #000000;
    max-width: 0;
    overflow: hidden;
    padding: .25em 0;
    position: absolute;
    right: 2em;
    top: 1px;
    transition: all .5s ease-out; }
  #dbs-chev.hover span, #dbs-chev:hover span, #dbs-chev:focus span {
    max-width: 23em; }

/**
 * Footer styles
 * DBS>Interactive
 *
 * Classname Structure
 *
 * The rationale was to avoid using elements in the stylesheet and to limit nesting.
 *
 * 		site-footer - the footer element
 *
 * 			site-footer__navigation - footer menu
 * 				footer-menu__item - footer menu item
 *
 * 			site-footer__credits - copyright info container
 *
 * 			site-footer__copyright - copyright statement
 *
 */
#footer {
  border-top: 2px solid #ffffff;
  clear: both;
  margin-top: auto;
  overflow: hidden; }
  #footer #site-footer__wrapper {
    margin: 0 auto;
    max-width: 78rem;
    padding: 7rem 1.5rem 2rem;
    overflow: visible;
    position: relative; }
  #footer ul {
    list-style: none;
    margin: 0;
    padding: 0; }
    #footer ul li:first-of-type a {
      margin-top: -.5em; }
  #footer a:not(.social-media__link):not(#dbs-chev):not(.legal-link):not(.button):not(.to-top) {
    color: #ffffff;
    display: inline-block;
    font-weight: 400;
    margin-bottom: .5em;
    padding: 0.75em 0 .25em;
    position: relative;
    text-decoration: none; }
    #footer a:not(.social-media__link):not(#dbs-chev):not(.legal-link):not(.button):not(.to-top):after {
      border-bottom: 1px solid #ffffff;
      content: '';
      display: inline-block;
      opacity: 0;
      position: absolute;
      left: 0;
      bottom: 0;
      transform: translateY(0.5em);
      transition: .3s all;
      width: 100%; }
    #footer a:not(.social-media__link):not(#dbs-chev):not(.legal-link):not(.button):not(.to-top):hover:after, #footer a:not(.social-media__link):not(#dbs-chev):not(.legal-link):not(.button):not(.to-top):focus:after {
      opacity: 1;
      transform: translateY(0); }
  #footer .button--ghost-white {
    -ms-flex-item-align: start;
    align-self: flex-start;
    background: #232323;
    color: #ffffff;
    margin-top: 1.5em; }
    #footer .button--ghost-white:hover, #footer .button--ghost-white:focus {
      background: #ffffff;
      color: #232323; }
  #footer #site-footer__logo svg {
    display: block;
    max-width: 10em;
    padding-bottom: 1em; }
  #footer #site-footer__phone,
  #footer #site-footer__email,
  #footer #site-footer__location {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 0.5em; }
    #footer #site-footer__phone:before,
    #footer #site-footer__email:before,
    #footer #site-footer__location:before {
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      content: '';
      display: inline-block;
      height: 1.5em;
      margin-right: 1em;
      width: 1.5em; }
  #footer #site-footer__phone::before {
    background-image: url("/images/phone.svg"); }
  #footer #site-footer__email::before {
    background-image: url("/images/email.svg"); }
  #footer #site-footer__location::before {
    background-image: url("/images/location.svg"); }
  #footer #site-footer__content {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    position: relative; }
  #footer .column {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-bottom: 3em;
    width: 100%; }
    @media screen and (min-width: 550px) {
      #footer .column {
        padding: 0 1.5em;
        width: 50%; } }
    @media screen and (min-width: 900px) {
      #footer .column {
        margin-bottom: 0; }
        #footer .column.product {
          width: 25%; }
        #footer .column.contact, #footer .column.social {
          width: 37.5%; } }
    @media screen and (min-width: 62em) {
      #footer .column {
        padding: 0em; } }
    #footer .column h4,
    #footer .column .footer-column-heading {
      font-family: "Poppins", "Helvetica", sans-serif;
      font-size: 1em;
      font-weight: bold;
      letter-spacing: 1px;
      margin-bottom: 0;
      position: relative;
      text-transform: uppercase; }
      #footer .column h4:after,
      #footer .column .footer-column-heading:after {
        background: #86D1D4;
        content: '';
        display: block;
        height: 1px;
        margin: 1.5rem 0;
        width: 2em; }
  #footer #footer-contacts {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column; }
    #footer #footer-contacts li:first-child a {
      margin-top: 0; }
    #footer #footer-contacts #site-footer__phone {
      margin-top: -.5rem; }
  #footer .social-media {
    display: -ms-flexbox;
    display: flex; }
    #footer .social-media__link {
      margin-right: 1rem; }
      #footer .social-media__link:last-child {
        margin-right: 0; }
    #footer .social-media a {
      display: inline-block; }
    #footer .social-media svg {
      width: 2.5em; }
  #footer #site-footer__credits {
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin: 4em 0 0;
    padding: 1em 0 0;
    width: 100%; }
    #footer #site-footer__credits #legal-footer {
      color: #CFD6DC;
      font-size: .9em;
      font-weight: 400;
      width: 100%; }
      #footer #site-footer__credits #legal-footer ul {
        display: block;
        margin-top: 3em; }
        @media screen and (min-width: 48em) {
          #footer #site-footer__credits #legal-footer ul {
            margin: 0; } }
        #footer #site-footer__credits #legal-footer ul li {
          display: inline-block;
          margin-bottom: .5rem;
          margin-right: 1em; }
          #footer #site-footer__credits #legal-footer ul li:last-child {
            margin-right: 0; }
          #footer #site-footer__credits #legal-footer ul li a {
            color: #CFD6DC;
            font-weight: 400; }
    @media screen and (min-width: 48em) {
      #footer #site-footer__credits {
        margin: 6em 0 0; }
        #footer #site-footer__credits #legal-footer ul {
          display: inline-block; }
          #footer #site-footer__credits #legal-footer ul li {
            margin-right: 0; }
            #footer #site-footer__credits #legal-footer ul li:before {
              content: '|';
              display: inline-block;
              padding: 0 .75em; } }
  #footer .footer-subscribe {
    margin-bottom: 2em; }
    #footer .footer-subscribe form {
      position: relative; }
    #footer .footer-subscribe label,
    #footer .footer-subscribe input[type="email"],
    #footer .footer-subscribe input[type="submit"] {
      font-weight: 400; }
    #footer .footer-subscribe input:-ms-input-placeholder {
      color: #303337; }
    #footer .footer-subscribe input::placeholder {
      color: #303337; }
    #footer .footer-subscribe input[type="email"] {
      background-color: #FBFBFB;
      border: none;
      color: #000000;
      border-radius: 3px;
      margin-bottom: 1em;
      padding: 1rem; }
    #footer .footer-subscribe input[type="submit"] {
      background: #376A72;
      border: 1px solid #ffffff;
      border-radius: 3px;
      color: #ffffff;
      cursor: pointer;
      font-weight: bold;
      height: 100%;
      padding: 1em;
      width: 100%;
      transition: background-color .3s; }
      @media screen and (min-width: 48em) {
        #footer .footer-subscribe input[type="submit"] {
          border-radius: 0 3px 3px 0;
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateY(-50%);
          padding: 0 1.75em;
          width: auto; } }
      #footer .footer-subscribe input[type="submit"]:hover, #footer .footer-subscribe input[type="submit"]:focus {
        background-color: #60c3c7; }
  #footer .site-footer__navigation .menu {
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-direction: column;
    flex-direction: column; }

.scroll-to-top {
  background-color: #ffffff;
  border: none;
  border-radius: 100%;
  box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.21);
  display: block;
  padding: .5em;
  position: absolute;
  right: 1em;
  top: -1.5em;
  transition: transform .35s; }
  .scroll-to-top:after {
    margin-left: 0;
    background: url("/images/cta-arrow.svg") no-repeat center;
    background-size: contain;
    content: '';
    display: inline-block;
    height: 1.75em;
    position: relative;
    transform: rotate(-90deg) translateY(0em);
    vertical-align: middle;
    width: 1.75em;
    transition: .35s; }
  .scroll-to-top:hover, .scroll-to-top:focus {
    transform: translateY(-0.25em); }

/**
 * Form Styles
 * DBS>Interactive
 */
.form {
  /**
	* Field Widths/Wrapping
	*/ }
  .form ul {
    list-style: none;
    padding: 0; }
  .form label, .form legend {
    font-weight: bold; }
  .form__field {
    margin-bottom: 1em; }
  .form .error-text {
    border-bottom: 0.1em solid #363535;
    color: #363535;
    padding-bottom: 1em; }
  @media screen and (min-width: 48em) {
    .form {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      margin: 0 -.75rem; }
      .form__field-wrapper {
        padding: 0 .75rem;
        width: 100%; }
        .form__field-wrapper.width-25 {
          width: 25%; }
        .form__field-wrapper.width-33 {
          width: 33.33%; }
        .form__field-wrapper.width-50 {
          width: 50%; }
        .form__field-wrapper.width-66 {
          width: 66.66%; }
        .form__field-wrapper.width-75 {
          width: 75%; }
      .form .button {
        margin-left: .75rem; } }

fieldset > label.gfield_label_before_complex,
fieldset legend.gfield_label_before_complex,
.hidden_label > label.gfield_label_before_complex,
.hidden_label legend.gfield_label_before_complex,
.hide-label > label.gfield_label_before_complex,
.hide-label legend.gfield_label_before_complex {
  display: none; }

fieldset > label, fieldset legend,
.hidden_label > label,
.hidden_label legend,
.hide-label > label,
.hide-label legend {
  height: 1px;
  width: 1px;
  border: 0;
  clip: rect(0 0 0 0);
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute; }

/**
 * Input Field Styles
 */
textarea {
  min-height: 5em; }

input[type=radio] {
  margin-right: .5em; }

select {
  min-width: 25%;
  margin: 0; }

textarea,
input[type=text]:not([id=blog-search-field]),
input[type=email],
input[type=date],
input[type=number],
input[type=time],
input[type=week],
input[type=month],
input[type=tel],
input[type=search],
input[type=url],
input[type=password],
.gfield select {
  display: block;
  border: 1px solid #E9E9E9;
  margin: .333em 0;
  max-width: 100%;
  padding: .666em;
  width: 100%; }

fieldset {
  border: none;
  margin-bottom: 3rem;
  padding: 0 !important; }

/**
 * Validation and error message styling
 */
.error-text {
  color: red; }

/**
 * Animated Form Label
 *
 * Class must be added in the GForms admin
 */
.animate-label {
  position: relative; }
  .animate-label .ginput_complex > span {
    height: 100%;
    width: 100%;
    display: inline-block;
    position: relative; }
    .gform_validation_error .animate-label label, .gform_validation_error .animate-label legend, .animate-label .ginput_complex > span.active label, .animate-label .ginput_complex > span.active legend {
      transform: translateY(-2.25rem) scale(0.75); }
  .animate-label .ginput_complex.gfield_error label {
    transform: translateY(-2.25rem) scale(0.75); }
  .animate-label label, .animate-label legend {
    background: white;
    padding: 0 .25rem;
    position: absolute;
    top: 50%;
    left: .5rem;
    transition: .2s ease-in-out;
    transform: translateY(-50%);
    transform-origin: left; }
  .animate-label.select-field select {
    font-weight: 700;
    padding: 1.5rem .75rem; }
  .animate-label.text-area label {
    top: .5rem;
    transform: none; }
  .animate-label.text-area textarea {
    max-height: 12rem;
    padding-top: 3rem; }
  .animate-label.text-area.gfield_error label, .animate-label.text-area.gfield_error legend {
    transform: none; }
  .gform_validation_error .animate-label label, .gform_validation_error .animate-label legend, .animate-label.active label, .animate-label.active legend {
    transform: translateY(-2.25rem) scale(0.75); }

.customer-login .form {
  margin: 0 auto;
  max-width: 35rem; }

.customer-login .field {
  margin-bottom: 1rem;
  width: 100%; }

.customer-login__links {
  text-align: center; }
  .customer-login__links .button {
    margin: 0;
    width: 100%; }

/**
 * Exploding house styles
 *
 * Includes static styles and animation
 */
.exploding-house-container {
  color: #909193;
  display: block;
  margin: 0 auto;
  max-height: 700px;
  max-width: 700px;
  overflow: visible;
  position: relative;
  width: 55vw;
  height: 75vw;
  width: 75vw;
  margin: 0 auto; }
  @media screen and (min-width: 48em) {
    .exploding-house-container {
      height: 100vw;
      width: 100vw; } }
  .exploding-house-container img {
    height: auto;
    position: absolute; }

div.exploding-house {
  position: relative;
  z-index: 1; }

.exploding-house.panel-00 {
  top: 37.25%;
  left: 29%;
  width: 55.7%; }

.exploding-house.panel-01 {
  top: 21.5%;
  left: 54.75%;
  width: 30%; }

.exploding-house.panel-02 {
  top: 21.15%;
  left: 29%;
  width: 26.5%; }

.exploding-house.panel-03 {
  top: 25.5%;
  left: 44.25%;
  width: 21.5%; }

.exploding-house.panel-04 {
  top: 35%;
  left: 30.25%;
  width: 35%; }

.exploding-house.panel-05 {
  top: 28.95%;
  left: 28.5%;
  width: 30%; }

.exploding-house.panel-06 {
  top: 37.75%;
  left: 20.75%;
  width: 37%; }

.exploding-house.panel-07 {
  top: 35.5%;
  left: 77.15%;
  width: 7.75%; }

.exploding-house.panel-08 {
  top: 37.5%;
  left: 71%;
  width: 6.85%; }

.exploding-house.panel-09 {
  top: 39.15%;
  left: 65%;
  width: 7.1%; }

.exploding-house.panel-10 {
  top: 41.15%;
  left: 58.25%;
  width: 7.25%; }

.exploding-house.panel-11 {
  top: 18.5%;
  left: 39.8%;
  width: 20.5%; }

.exploding-house.panel-12 {
  top: 22.2%;
  left: 26.1%;
  width: 20.5%; }

.exploding-house.panel-13 {
  top: 22.35%;
  left: 48.5%;
  width: 20.5%; }

.exploding-house.panel-14 {
  top: 26.35%;
  left: 34.4%;
  width: 20.5%; }

.exploding-house.panel-15 {
  top: 26.25%;
  left: 56.75%;
  width: 20.45%; }

.exploding-house.panel-16 {
  top: 30.25%;
  left: 42.5%;
  width: 18.25%; }

.exploding-house.panel-17 {
  top: 30.15%;
  left: 67%;
  width: 18.5%; }

.exploding-house-container.animate .panel-00 {
  animation: panel-00 4s linear 1; }

.exploding-house-container.animate .panel-01 {
  animation: panel-01 4s linear 1; }

.exploding-house-container.animate .panel-02 {
  animation: panel-02 4s linear 1; }

.exploding-house-container.animate .panel-03 {
  animation: panel-03 4s linear 1; }

.exploding-house-container.animate .panel-04 {
  animation: panel-04 4s linear 1; }

.exploding-house-container.animate .panel-05 {
  animation: panel-05 4s linear 1; }

.exploding-house-container.animate .panel-06 {
  animation: panel-06 4s linear 1; }

.exploding-house-container.animate .panel-10 {
  animation: panel-10 4s linear 1; }

.exploding-house-container.animate .panel-09 {
  animation: panel-09 3.3s linear 1 0.35s; }

.exploding-house-container.animate .panel-08 {
  animation: panel-08 2.6s linear 1 0.7s; }

.exploding-house-container.animate .panel-07 {
  animation: panel-07 1.9s linear 1 1.05s; }

.exploding-house-container.animate .panel-11 {
  animation: panel-11 4s linear 1; }

.exploding-house-container.animate .panel-12 {
  animation: panel-12 3.6s linear 1 0.2s; }

.exploding-house-container.animate .panel-13 {
  animation: panel-13 3.2s linear 1 0.4s; }

.exploding-house-container.animate .panel-14 {
  animation: panel-14 2.8s linear 1 0.6s; }

.exploding-house-container.animate .panel-15 {
  animation: panel-15 2.4s linear 1 0.8s; }

.exploding-house-container.animate .panel-16 {
  animation: panel-16 2s linear 1 1s; }

.exploding-house-container.animate .panel-17 {
  animation: panel-17 1.6s linear 1 1.2s; }

.exploding-house-container.paused .exploding-house {
  animation-play-state: paused; }

/*
 * Animations
 */
@keyframes panel-00 {
  0% {
    transform: translate3d(0, 0, 0); }
  20% {
    transform: translate3d(0, 10%, 0); }
  80% {
    transform: translate3d(0, 10%, 0); }
  100% {
    transform: translate3d(0, 0, 0); } }

@keyframes panel-01 {
  0% {
    transform: translate3d(0, 0, 0); }
  20% {
    transform: translate3d(15%, -25%, 0); }
  80% {
    transform: translate3d(15%, -25%, 0); }
  100% {
    transform: translate3d(0, 0, 0); } }

@keyframes panel-02 {
  0% {
    transform: translate3d(0, 0, 0); }
  20% {
    transform: translate3d(-35%, -15%, 0); }
  80% {
    transform: translate3d(-35%, -15%, 0); }
  100% {
    transform: translate3d(0, 0, 0); } }

@keyframes panel-03 {
  0% {
    transform: translate3d(0, 0, 0); }
  20% {
    transform: translate3d(-5%, -15%, 0); }
  80% {
    transform: translate3d(-5%, -15%, 0); }
  100% {
    transform: translate3d(0, 0, 0); } }

@keyframes panel-04 {
  0% {
    transform: translate3d(0, 0, 0); }
  20% {
    transform: translate3d(0%, -35%, 0); }
  80% {
    transform: translate3d(0%, -35%, 0); }
  100% {
    transform: translate3d(0, 0, 0); } }

@keyframes panel-05 {
  0% {
    transform: translate3d(0, 0, 0); }
  20% {
    transform: translate3d(-40%, 40%, 0); }
  80% {
    transform: translate3d(-40%, 40%, 0); }
  100% {
    transform: translate3d(0, 0, 0); } }

@keyframes panel-06 {
  0% {
    transform: translate3d(0, 0, 0); }
  20% {
    transform: translate3d(-50%, 70%, 0); }
  80% {
    transform: translate3d(-50%, 70%, 0); }
  100% {
    transform: translate3d(0, 0, 0); } }

@keyframes panel-07 {
  0% {
    transform: translate3d(0, 0, 0); }
  20% {
    transform: translate3d(75%, 10%, 0); }
  80% {
    transform: translate3d(75%, 10%, 0); }
  100% {
    transform: translate3d(0, 0, 0); } }

@keyframes panel-08 {
  0% {
    transform: translate3d(0, 0, 0); }
  20% {
    transform: translate3d(55%, 30%, 0); }
  80% {
    transform: translate3d(55%, 30%, 0); }
  100% {
    transform: translate3d(0, 0, 0); } }

@keyframes panel-09 {
  0% {
    transform: translate3d(0, 0, 0); }
  20% {
    transform: translate3d(35%, 50%, 0); }
  80% {
    transform: translate3d(35%, 50%, 0); }
  100% {
    transform: translate3d(0, 0, 0); } }

@keyframes panel-10 {
  0% {
    transform: translate3d(0, 0, 0); }
  20% {
    transform: translate3d(15%, 70%, 0); }
  80% {
    transform: translate3d(15%, 70%, 0); }
  100% {
    transform: translate3d(0, 0, 0); } }

@keyframes panel-11 {
  0% {
    transform: translate3d(0, 0, 0); }
  20% {
    transform: translate3d(5%, -135%, 0); }
  80% {
    transform: translate3d(5%, -135%, 0); }
  100% {
    transform: translate3d(0, 0, 0); } }

@keyframes panel-12 {
  0% {
    transform: translate3d(0, 0, 0); }
  20% {
    transform: translate3d(-30%, -115%, 0); }
  80% {
    transform: translate3d(-30%, -115%, 0); }
  100% {
    transform: translate3d(0, 0, 0); } }

@keyframes panel-13 {
  0% {
    transform: translate3d(0, 0, 0); }
  20% {
    transform: translate3d(15%, -115%, 0); }
  80% {
    transform: translate3d(15%, -115%, 0); }
  100% {
    transform: translate3d(0, 0, 0); } }

@keyframes panel-14 {
  0% {
    transform: translate3d(0, 0, 0); }
  20% {
    transform: translate3d(-15%, -105%, 0); }
  80% {
    transform: translate3d(-15%, -105%, 0); }
  100% {
    transform: translate3d(0, 0, 0); } }

@keyframes panel-15 {
  0% {
    transform: translate3d(0, 0, 0); }
  20% {
    transform: translate3d(25%, -110%, 0); }
  80% {
    transform: translate3d(25%, -110%, 0); }
  100% {
    transform: translate3d(0, 0, 0); } }

@keyframes panel-16 {
  0% {
    transform: translate3d(0, 0, 0); }
  20% {
    transform: translate3d(0, -130%, 0); }
  80% {
    transform: translate3d(0, -130%, 0); }
  100% {
    transform: translate3d(0, 0, 0); } }

@keyframes panel-17 {
  0% {
    transform: translate3d(0, 0, 0); }
  20% {
    transform: translate3d(40%, -95%, 0); }
  80% {
    transform: translate3d(40%, -95%, 0); }
  100% {
    transform: translate3d(0, 0, 0); } }

.mega-menu:hover .menu__submenu {
  pointer-events: all; }

.mega-menu .menu__submenu {
  left: -15rem;
  pointer-events: none;
  width: auto; }

.mega-menu__wrapper {
  width: 57rem; }

.mega-menu__list {
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  width: 30%; }
  .mega-menu__list-item {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2); }
    .mega-menu__list-item:last-child {
      border: none; }
    .mega-menu__list-item .mega-menu__list-link {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
      align-items: center;
      font-size: .95rem;
      padding: .5rem 1rem;
      transition: .25s background; }
      .mega-menu__list-item .mega-menu__list-link:hover, .mega-menu__list-item .mega-menu__list-link:focus, .mega-menu__list-item .mega-menu__list-link:active {
        background: #232323; }
  .mega-menu__list-image {
    height: 0;
    overflow: hidden;
    padding-bottom: 23%;
    position: relative;
    width: 40%; }
    .mega-menu__list-image img {
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%; }
      @supports (object-fit: contain) {
        .mega-menu__list-image img {
          height: 100%;
          object-fit: cover;
          object-position: center; } }
  .mega-menu__list-title {
    padding: 1rem;
    width: 60%; }

.mega-menu__panel {
  width: 70%; }
  .mega-menu__panel-item {
    opacity: 0;
    padding: 1.5rem;
    position: absolute;
    visibility: hidden;
    width: 100%; }
    .mega-menu__panel-item.current-item-previewed {
      opacity: 1;
      position: relative;
      visibility: visible; }
  .mega-menu__panel-image {
    height: 0;
    overflow: hidden;
    padding-bottom: 60%;
    position: relative;
    width: 100%; }
    .mega-menu__panel-image img {
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%; }
      @supports (object-fit: contain) {
        .mega-menu__panel-image img {
          height: 100%;
          object-fit: cover;
          object-position: center; } }
  .mega-menu__panel-arrow {
    background: url("/icons/arrow-right-teal.svg") no-repeat right;
    background-size: 1rem;
    padding-right: 1.5rem; }
  .mega-menu__panel-sizes {
    margin-top: .25rem !important; }
  .mega-menu__panel-size:not(:last-child) {
    border-right: 1px solid rgba(255, 255, 255, 0.2);
    margin-right: 1rem;
    padding-right: 1rem; }
  .mega-menu__panel-size a {
    border-bottom: 2px solid transparent;
    padding: 0;
    transition: border-color .25s ease-in-out; }
    .mega-menu__panel-size a:hover, .mega-menu__panel-size a:focus {
      border-color: #376A72; }

.mega-menu__bottom a {
  background: #376A72;
  padding: 1rem 2rem;
  transition: .25s background, .25s color; }
  .mega-menu__bottom a:first-child {
    border-right: 1px solid #ffffff; }
  .mega-menu__bottom a:hover, .mega-menu__bottom a:focus, .mega-menu__bottom a:active {
    background: #ffffff;
    color: #376A72; }

/**
 * DBS Slate Stylesheet
 * DBS>Interactive
 */
.page- .home-hero {
  margin-bottom: 0; }

.page- .kit-slider .contain,
.page- .kit-slider + .two-columns .contain,
.page- .how-it-works .contain {
  position: relative;
  z-index: 1; }

.page- .kit-slider {
  padding-bottom: 3rem;
  padding-top: 3rem;
  background: #101010;
  margin-bottom: 0;
  margin-top: 0;
  position: relative; }
  @media screen and (min-width: 48em) {
    .page- .kit-slider {
      padding-bottom: 4.5rem;
      padding-top: 4.5rem; } }
  .page- .kit-slider::after {
    background: radial-gradient(39.75% 58.57% at 82.5% 16.06%, rgba(105, 105, 105, 0.4) 0%, #101010 100%);
    content: '';
    height: 300%;
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    z-index: 0; }
  .page- .kit-slider + .two-columns {
    padding-bottom: 3rem;
    padding-top: 3rem;
    background: #101010;
    margin-top: 0;
    margin-bottom: 0; }
    @media screen and (min-width: 48em) {
      .page- .kit-slider + .two-columns {
        padding-bottom: 4.5rem;
        padding-top: 4.5rem; } }

.page- .kit-slider__right::before {
  background: #101010; }

.page- .how-it-works {
  padding-bottom: 3rem;
  padding-top: 3rem;
  padding-top: 0 !important;
  background: linear-gradient(to bottom, #101010, #232323);
  margin-bottom: 0;
  margin-top: 0;
  position: relative; }
  @media screen and (min-width: 48em) {
    .page- .how-it-works {
      padding-bottom: 4.5rem;
      padding-top: 4.5rem; } }
  .page- .how-it-works::before {
    background: radial-gradient(50% 50% at 50% 50%, #20343A 0%, rgba(30, 45, 50, 0) 100%);
    content: '';
    height: 200%;
    position: absolute;
    top: -50%;
    left: -50%;
    width: 100%;
    z-index: 0; }
