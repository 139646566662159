/**
 * Footer styles
 * DBS>Interactive
 *
 * Classname Structure
 *
 * The rationale was to avoid using elements in the stylesheet and to limit nesting.
 *
 * 		site-footer - the footer element
 *
 * 			site-footer__navigation - footer menu
 * 				footer-menu__item - footer menu item
 *
 * 			site-footer__credits - copyright info container
 *
 * 			site-footer__copyright - copyright statement
 *
 */

 #footer {
	border-top: 2px solid $white;
	clear: both;
	margin-top: auto; // Sticky footer
	overflow: hidden;

	#site-footer__wrapper {
		margin: 0 auto;
		max-width: $containSize;
		padding: 7rem $spacing 2rem;
        overflow: visible;
        position: relative;
	}

	// Resets
	ul {
		list-style: none;
		margin: 0;
		padding: 0;

		li:first-of-type a {margin-top: -.5em;} //Counters padding for top alignment
	}

	// Default link styles
	a:not(.social-media__link):not(#dbs-chev):not(.legal-link):not(.button):not(.to-top) {
		color: $white;
		display: inline-block;
		font-weight: 400;
		margin-bottom: .5em;
		padding: 0.75em 0 .25em;
		position: relative;
		text-decoration: none;

		&:after {
			border-bottom: 1px solid $white;
			content: '';
			display: inline-block;
			opacity: 0;
			position: absolute;
				left: 0;
				bottom: 0;
			transform: translateY(.5em);
			transition: .3s all;
			width: 100%;
		}

		&:hover,
		&:focus {
			&:after {
				opacity: 1;
				transform: translateY(0);
			}
		}
	}

	.button--ghost-white {
		align-self: flex-start;
		background: $grayDarker;
		color: $white;
		margin-top: 1.5em;

		&:hover,
		&:focus {
			background: $white;
			color: $grayDarker;
		}
	}

	#site-footer__logo svg {
		display: block;
		max-width: 10em;
		padding-bottom: 1em;
	}
    
	#site-footer__phone,
	#site-footer__email,
	#site-footer__location {
		display: flex;
			align-items: center;
		margin-bottom: 0.5em;

		&:before {
			background-position: center;
			background-size: contain;
			background-repeat: no-repeat;
			content: '';
			display: inline-block;
			height: 1.5em;
			margin-right: 1em;
			width: 1.5em;
		}
	}

	#site-footer__phone::before {background-image: url('#{$libraryPath}/images/phone.svg');}
	#site-footer__email::before {background-image: url('#{$libraryPath}/images/email.svg');}
	#site-footer__location::before {background-image: url('#{$libraryPath}/images/location.svg');}

	#site-footer__content {
		display: flex;
			flex-wrap: wrap;
		position: relative;
	}

	.column {
		display: flex;
			flex-direction: column;
		margin-bottom: 3em;
		width: 100%;

		@include media(550px) {
			padding: 0 1.5em;
			width: 50%;
		}

		@include media(900px) {
			margin-bottom: 0;

			&.product {width: 25%;}
			&.contact,
			&.social {width: 37.5%;}
		}
        @include media($screen-md) {
            padding: 0em;
        }

		h4,
		.footer-column-heading {
			font-family: $font-poppins;
			font-size: 1em;
			font-weight: bold;
			letter-spacing: 1px;
			margin-bottom: 0;
			position: relative;
			text-transform: uppercase;

			&:after {
				background: $teal;
				content: '';
				display: block;
				height: 1px;
				margin: 1.5rem 0;
				width: 2em;
			}
		}
	}

	#footer-contacts {
		display: flex;
			flex-direction: column;
        
        li:first-child a {
            margin-top: 0;
        }
        
        #site-footer__phone {
            margin-top: -.5rem;
        }
	}

	.social-media {
		display: flex;

		&__link {
			margin-right: 1rem;

			&:last-child {margin-right: 0;}
		}

		a {
			display: inline-block;
		}

		svg {
			width: 2.5em;
		}
	}

	#site-footer__credits {
		border-top: 1px solid rgba($white, .2);
		display: flex;
			align-items: center;
			justify-content: space-between;
		margin: 4em 0 0;
		padding: 1em 0 0;
		width: 100%;

		#legal-footer {
			color: #CFD6DC;
			font-size: .9em;
			font-weight: 400;
			width: 100%;

			ul {
				display: block;
				margin-top: 3em;

				@include media($screen-sm) {margin: 0;}

				li {
					display: inline-block;
					margin-bottom: .5rem;
					margin-right: 1em;

					&:last-child {margin-right: 0;}

					a {
						color: #CFD6DC;
						font-weight: 400;
					}
				}
			}
		}

		@include media($screen-sm) {
			margin: 6em 0 0;

			#legal-footer {
				ul {
					display: inline-block;

					li {
						margin-right: 0;

						&:before {
							content: '|';
							display: inline-block;
							padding: 0 .75em;
						}
					}
				}
			}
		}
	}

	.footer-subscribe {
		margin-bottom: 2em;

		form {
			position: relative;
		}

		label,
		input[type="email"],
		input[type="submit"] {
			font-weight: 400;
		}

		input::placeholder {
			color: #303337;
		}

		input[type="email"] {
			background-color: #FBFBFB;
			border: none;
			color: $black;
			border-radius: 3px;
			margin-bottom: 1em;
			padding: 1rem;
		}

		input[type="submit"] {
			background: $tealDark;
			border: 1px solid $white;
			border-radius: 3px;
			color: $white;
			cursor: pointer;
			font-weight: bold;
			height: 100%;
            padding: 1em;
            width: 100%;
			transition: background-color .3s;

            @include media($screen-sm) {
				border-radius: 0 3px 3px 0;
                position: absolute;
    				top: 50%;
    				right: 0;
    			transform: translateY(-50%);
                padding: 0 1.75em;
                width: auto;
            }
			&:hover,
			&:focus {
				background-color: darken($teal, 10%);
			}
		}
	}
    
    .site-footer__navigation {
        .menu {
            align-items: flex-start;
            flex-direction: column;
        }
    }
}


.scroll-to-top {
	background-color: $white;
	border: none;
    border-radius: 100%;
    box-shadow: 0 2px 14px 0 rgba(0,0,0,0.21);
    display: block;
    padding: .5em;
    position: absolute;
        right: 1em;
		top: -1.5em;
	transition: transform .35s;

    &:after {
        margin-left: 0;
        background: url("#{$libraryPath}/images/cta-arrow.svg") no-repeat center;
			background-size: contain;
        content: '';
        display: inline-block;
		height: 1.75em;
		position: relative;
        transform: rotate(-90deg) translateY(0em);
        vertical-align: middle;
        width: 1.75em;
        transition: .35s;
	}

	&:hover,
	&:focus {
		transform: translateY(-.25em);
	}
}
